import { type FC, type PropsWithChildren, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'flowbite-react';
import { HTTPError } from 'ky';
import { Helmet } from 'react-helmet';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  type UserSignUpData,
  postUserSignUp,
} from 'domains/api/postUserSignUp';
import { usePageTracking } from 'utils/ga';
import PublicLayout from 'components/templates/PublicLayout';

const SignUpForm: FC = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserSignUpData>({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      passwordConfirmation: '',
    },
  });

  const onSubmit: SubmitHandler<UserSignUpData> = async (data) => {
    try {
      setLoading(true);
      const response = await postUserSignUp(data);

      if (response.ok) {
        setLoading(false);
        navigate('/users/sign_in');
      }
    } catch (error: unknown) {
      setLoading(false);
      if (error instanceof HTTPError) {
        const errorMessages = (await error.response.json()) as string[];
        errorMessages.forEach((errorMsg) => toast.error(errorMsg));
      }
    }
  };

  return (
    <form
      className="new_contact"
      id="new_contact"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="p-contact__content">
        <p className="p-contact__title">
          <span>
            <label className="p-contact__titleMain" htmlFor="name">
              お名前
            </label>
          </span>
          <span className="p-contact__titleSupplement">( 必須 )</span>
        </p>
        <input
          className="p-contact__input"
          id="name"
          {...register('name', { required: true })}
        />

        {errors.name && (
          <div className="error_messages">お名前を入力してください</div>
        )}
      </div>
      <div className="p-contact__content">
        <p className="p-contact__title">
          <span>
            <label className="p-contact__titleMain" htmlFor="email">
              メールアドレス
            </label>
          </span>
          <span className="p-contact__titleSupplement">( 必須 )</span>
        </p>
        <input
          className="p-contact__input"
          type="email"
          id="email"
          {...register('email', { required: true })}
        />
        {errors.email && (
          <div className="error_messages">メールアドレスを入力してください</div>
        )}
      </div>
      <div className="p-contact__content">
        <p className="p-contact__title">
          <span>
            <label className="p-contact__titleMain" htmlFor="password">
              パスワード
            </label>
          </span>
          <span className="p-contact__titleSupplement">( 必須 )</span>
        </p>
        <input
          className="p-contact__input"
          type="password"
          id="password"
          {...register('password', { required: true })}
        />
        {errors.password && (
          <div className="error_messages">パスワードを入力してください</div>
        )}
      </div>
      <div className="p-contact__content">
        <p className="p-contact__title">
          <span>
            <label
              className="p-contact__titleMain"
              htmlFor="passwordConfirmation"
            >
              パスワード(確認用)
            </label>
          </span>
          <span className="p-contact__titleSupplement">( 必須 )</span>
        </p>
        <input
          className="p-contact__input"
          type="password"
          id="passwordConfirmation"
          {...register('passwordConfirmation', { required: true })}
        />
        {errors.email && (
          <div className="error_messages">
            パスワード(確認用)を入力してください
          </div>
        )}
      </div>
      <div className="p-contact__content">
        <Button type="submit" color="gray" isProcessing={loading}>
          ユーザー登録する
        </Button>
      </div>
    </form>
  );
};

const SignUp: FC<PropsWithChildren> = () => {
  usePageTracking(); // ページビュー計測

  return (
    <PublicLayout>
      <Helmet>
        <title>ユーザー登録: サイト分析、レポートはGrowth Support</title>
      </Helmet>

      <section
        className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6 text-center"
        id="contact"
      >
        <div className="container p-login">
          <header className="c-section__header--narrow">
            <h2 className="c-section__title">ユーザー登録</h2>
          </header>
          <div className="c-section__body">
            <div className="p-contact">
              <div className="p-contact__container">
                <SignUpForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </PublicLayout>
  );
};

export default SignUp;
